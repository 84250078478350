import type { ComputedRef } from 'vue';

import { storeToRefs, computed } from '#imports';
import type { ServerResponseCommonType } from '~/types/commons';
// оставляю со скоупом на расширение
import type { OrderDataType, RestaurantId, OrderProductListResponceType, getOrderByIdRequestType, ChangeOrderStatusRequestType } from './types/PartnerOrdersTypes';

type AuthResponse = {
  auth: string;
};

type TransportOrderDataType = {
    getOrderById: (options: getOrderByIdRequestType) => Promise<OrderDataType | null>;
    changeOrderStatus: (options: ChangeOrderStatusRequestType) => Promise<OrderDataType | null>;
    getInWorkOrders: (id: number) => Promise<OrderProductListResponceType[] | null>;
    getOrdersHistory: (query: {restaurant_id: number, search?: number}, statuses?: number[]) => Promise<OrderProductListResponceType[] | null>;
    getWebsoketAuthToken: (body: {socket_id: string, channel_name: string}) => Promise<string>
    getOrderStatuses (): Promise<{id: number, description: string}[] | null>

}

export function usePartnerOrders (): TransportOrderDataType {
  const { $api } = useNuxtApp();
  async function getWebsoketAuthToken (body: {socket_id: string, channel_name: string}): Promise<string> {
    const response = await $api<ServerResponseCommonType<AuthResponse>>('broadcasting/auth', {
      method: 'POST',
      body: {
        ...body
      }
    });

    return response.data.auth;
  }

  const getOrderById = async (options: getOrderByIdRequestType): Promise<OrderDataType | null> => {
    const res = await $api<ServerResponseCommonType<OrderDataType>>(`/partner/order/getById?restaurant_id=${options.restaurant_id}&order_id=${options.order_id}`)
      .catch((err) => { throw err; });

    if (res && res.data) {
      return res.data;
    }

    return null;
  };

  const changeOrderStatus = async (options: ChangeOrderStatusRequestType): Promise<OrderDataType | null> => {
    const res = await $api<ServerResponseCommonType<OrderDataType>>('/partner/order/changeStatus',
      {
        method: 'POST',
        body: { ...options }
      })
      .catch((err) => { throw err; });
    if (res && res.data) {
      return res.data;
    }
    return null;
  };

  const getInWorkOrders = async (id: number): Promise<OrderProductListResponceType[] | null> => {
    const res = await $api<ServerResponseCommonType<OrderProductListResponceType[] | null>>(`/partner/order/getInWorkOrders?restaurant_id=${id}`)
      .catch((err) => { throw err; });
    if (res && res.data) {
      return res.data;
    }
    return null;
  };

  const getOrderStatuses = async (): Promise<{id: number, description: string}[] | null> => {
    const res = await $api<{id: number, description: string}[]>('/order/getStatuses');
    if (res) {
      return res;
    }
    return null;
  };
  const getOrdersHistory = async (query: {search?: number, restaurant_id: number}, statuses?: number[]):
  Promise<OrderProductListResponceType[] | null> => {
    function formatStatusesQuery (array: number[] | undefined) {
      if (array) {
        return array.reduce((acc, value, index) => {
          // @ts-ignore
          acc[`statuses[${index}]`] = value;
          return acc;
        }, {});
      }
      return {};
    }
    const formatedStatuses = formatStatusesQuery(statuses);
    const res = await $api<ServerResponseCommonType<OrderProductListResponceType[] | null>>('/partner/order/getHistory?', {
      query: {
        ...query,
        ...formatedStatuses

      }
    })
      .catch((err) => { throw err; });
    if (res && res.data) {
      return res.data;
    }
    return null;
  };

  return {
    getOrderById,
    changeOrderStatus,
    getInWorkOrders,
    getOrdersHistory,
    getWebsoketAuthToken,
    getOrderStatuses
  };
}
